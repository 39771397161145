import dynamic from "next/dynamic"
import { createContext, useContext, useEffect, useMemo, useState } from "react"
import { useInView } from "react-intersection-observer"

import { TrackedComponent, TrackedComponents } from "@spatialsys/react/analytics"
import { useGetFeatureFlagsQuery } from "@spatialsys/react/query-hooks/feature-flags"
import { screensPx } from "@spatialsys/theme/screens"
import { useAppContext, useAuthState } from "@spatialsys/web/app-context"
import { TryToAuthenticate } from "@spatialsys/web/core/js/components/auth/try-to-authenticate"
import { Footer } from "@spatialsys/web/core/js/components/footer/footer"
import { Navbar } from "@spatialsys/web/core/js/components/navbar/navbar"
import { SpacesFeed } from "@spatialsys/web/core/js/components/spaces-feed/spaces-feed"
import { Storage } from "@spatialsys/web/storage"
import { Container, toast } from "@spatialsys/web/ui"
import { useWindowSize } from "@spatialsys/web/ui/hooks/use-window-size"

import { AuthlessHeaderSection } from "./authless-header-section/authless-header-section"
import { FeaturedPlayer } from "./featured-player"
import { HeroCarousel } from "./hero-carousel/hero-carousel"
import { AvatarCustomization } from "./marketing-sections/rebranding/avatar-customization"
import { BrandMarquee } from "./marketing-sections/rebranding/brand-marquee"
import { CreateYourWorld } from "./marketing-sections/rebranding/create-your-world"
import { CrossPlatform } from "./marketing-sections/rebranding/cross-platform"
import { Explore } from "./marketing-sections/rebranding/explore"
import { Metrics } from "./marketing-sections/rebranding/metrics"
import { UseCase } from "./marketing-sections/rebranding/use-case"
import { VirtualExperience } from "./marketing-sections/rebranding/virtual-experience"

import classes from "./homepage.module.scss"

type HomepageProps = {
  userAgent: string
  locale: string
}

/**
 * Context for the locale. This is used to determine if the user is viewing the homepage in Korean.
 */
const LocaleContext = createContext<string>("")
export const useLocale = () => useContext(LocaleContext)

export function Homepage({ userAgent, locale }: HomepageProps) {
  // If authenticated and onboarding is not complete, we redirect to onboarding
  const { isAuthenticated, isAuthless } = useAuthState()
  const actions = useAppContext((context) => context.actions)
  const featuredInView = useAppContext((context) => context.state.canvas.isFeaturedInView)

  const ffQuery = useGetFeatureFlagsQuery()
  const isJumpCutEnabled = ffQuery.data?.featureFlags?.jumpCut
  const isNewAuthlessHomepage = ffQuery.data?.featureFlags?.newAuthlessHomepage

  const windowSize = useWindowSize()
  const isSmallScreen = Boolean(windowSize.width && windowSize.width <= screensPx.sm)

  const [isHeaderInView, setIsHeaderInView] = useState<boolean | null>(null)
  const [isMetricsInView, setIsMetricsInView] = useState<boolean | null>(null)

  const showOldHomepage = useAppContext((context) => context.state.showOldHomepage)

  const threshold = useMemo(() => {
    if (!isJumpCutEnabled) {
      return 0.1
    }
    return featuredInView ? 0 : 0.5
  }, [featuredInView, isJumpCutEnabled])

  const [featuredRef] = useInView({
    threshold,
    onChange: actions.setFeaturedInView,
  })

  const [headerRef] = useInView({
    threshold: 0.1,
    onChange: (inView) => setIsHeaderInView(inView),
  })

  const [metricsRef] = useInView({
    threshold: 0.1,
    onChange: (inView) => setIsMetricsInView(inView),
  })

  useEffect(() => {
    const hasAccountDeleted = Storage.fetch(Storage.HAS_ACCOUNT_DELETED)
    if (hasAccountDeleted === "true") {
      toast("Your account has been deleted", {
        duration: 10000,
        onDismiss: () => {
          Storage.removeItem(Storage.HAS_ACCOUNT_DELETED)
        },
        onAutoClose: () => {
          Storage.removeItem(Storage.HAS_ACCOUNT_DELETED)
        },
      })
    }
  }, [])

  useEffect(() => {
    return () => actions.setFeaturedInView(null)
  }, [actions])

  const navbarColor: React.ComponentProps<typeof Navbar>["color"] = useMemo(() => {
    // Handle old homepage logic
    if (!isNewAuthlessHomepage) {
      if (isJumpCutEnabled) {
        return "translucent"
      }

      if (featuredInView === null) {
        return "transparent"
      }

      return featuredInView ? "transparent" : undefined
    }

    // Handle new homepage logic
    const isNewAuthlessHomepageWithHeaderNotVisible = !showOldHomepage && isHeaderInView === false
    const isNewAuthenticatedHomepageWithCarouselNotVisible =
      isAuthenticated && !isAuthless && !showOldHomepage && featuredInView === false

    if (isNewAuthlessHomepageWithHeaderNotVisible || isNewAuthenticatedHomepageWithCarouselNotVisible) {
      return "translucent"
    }

    const isInitialRender =
      featuredInView === null ||
      (!showOldHomepage && isHeaderInView === null) ||
      (isAuthenticated && !isAuthless && !showOldHomepage && featuredInView === null)

    if (isInitialRender) {
      return "transparent" // Avoid flash on initial render
    }

    const isTopSectionVisible = showOldHomepage ? featuredInView : isHeaderInView

    return isTopSectionVisible ? "transparent" : undefined
  }, [
    featuredInView,
    isAuthenticated,
    isAuthless,
    isHeaderInView,
    isJumpCutEnabled,
    isNewAuthlessHomepage,
    showOldHomepage,
  ])

  return (
    <LocaleContext.Provider value={locale}>
      <TrackedComponent id={TrackedComponents.Homepage} as="div" className="flex min-h-screen flex-col">
        <TryToAuthenticate>
          <Navbar applyNegativeMarginTop={!isJumpCutEnabled} color={navbarColor} />
          <div className={classes.mainContent}>
            {(() => {
              // If the user is authenticated or authless, and the saas homepage is enabled, show the new saas landing page
              if ((!isAuthenticated || isAuthless) && isNewAuthlessHomepage && !showOldHomepage) {
                return (
                  <>
                    <AuthlessHeaderSection
                      ref={headerRef}
                      inView={Boolean(isHeaderInView)}
                      isSmallScreen={isSmallScreen}
                    />
                    <div className="mx-auto w-full max-w-screen-4xl">
                      <BrandMarquee isSmallScreen={isSmallScreen} />
                    </div>
                    <UseCase isSmallScreen={isSmallScreen} />
                    <Container className="flex w-[80%] flex-col content-center px-4">
                      <CreateYourWorld isSmallScreen={isSmallScreen} />
                    </Container>
                    <Container className="flex flex-col content-center px-4">
                      <CrossPlatform isSmallScreen={isSmallScreen} />
                    </Container>
                    <Container className="flex max-w-screen-2xl flex-col content-center !p-0">
                      <AvatarCustomization isSmallScreen={isSmallScreen} />
                    </Container>
                    <Explore isSmallScreen={isSmallScreen} />
                    <Metrics ref={metricsRef} inView={Boolean(isMetricsInView)} />
                    <Container className="flex max-w-screen-2xl flex-col content-center !p-0">
                      <VirtualExperience isSmallScreen={isSmallScreen} />
                    </Container>
                  </>
                )
              }
              // Otherwise, show the old homepage
              return (
                <>
                  {isJumpCutEnabled ? (
                    <FeaturedPlayer
                      ref={featuredRef}
                      closedFallback={<HeroCarousel inView={Boolean(featuredInView)} />}
                    />
                  ) : (
                    <div className="h-[80vh] max-h-[1280px] min-h-[480px]">
                      <HeroCarousel ref={featuredRef} inView={Boolean(featuredInView)} />
                    </div>
                  )}
                  <div className={classes.spacesContainer}>
                    <Container className="flex flex-col content-center">
                      <SpacesFeed userAgent={userAgent} />
                    </Container>
                  </div>
                  {(!isAuthenticated || isAuthless) && <MarketingContent />}
                </>
              )
            })()}
          </div>
          <Footer />
        </TryToAuthenticate>
      </TrackedComponent>
    </LocaleContext.Provider>
  )
}

const FreeOnlineGames = dynamic(() =>
  import("./marketing-sections/free-online-games/free-online-games").then((mod) => mod.FreeOnlineGames)
)

const BuildOnSpatial = dynamic(() =>
  import("./marketing-sections/build-on-spatial/build-on-spatial").then((mod) => mod.BuildOnSpatial)
)

const BrandExperiences = dynamic(() =>
  import("./marketing-sections/brand-experiences/brand-experiences").then((mod) => mod.BrandExperiences)
)
const Press = dynamic(() => import("./marketing-sections/press/press").then((mod) => mod.Press))

const WhatIsSpatial = dynamic(() =>
  import("./marketing-sections/what-is-spatial/what-is-spatial").then((mod) => mod.WhatIsSpatial)
)

function MarketingContent() {
  return (
    <section className="w-full space-y-12 pb-4 xs:pb-6 md:space-y-20 md:pb-8 lg:space-y-24 xl:space-y-28 2xl:space-y-32">
      <FreeOnlineGames />
      <BuildOnSpatial />
      <BrandExperiences />
      <Press />
      <WhatIsSpatial />
    </section>
  )
}
